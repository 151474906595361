import { useEffect, useRef, useState } from 'react';
import {
  getCourseDetails,
  getCourseInfo,
  getEnrollmentStatus,
  getStripeProduct,
} from './utils/courseDetail';
import { resolve } from 'path-browserify';
import { rejects } from 'assert';

function useCourseDetail(courseID) {
  const [courseInfo, setCourseInfo] = useState({});
  const [stripeProduct, setStripeProduct] = useState(null);
  const [stripeProductLoaded, setStripeProductLoaded] = useState(false);
  const [courseInfoLoaded, setCourseInfoLoaded] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseDetailsLoaded, setCourseDetailsLoaded] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState({});
  const [enrollmentStatusLoaded, setEnrollmentStatusLoaded] = useState(false);
  const [noCourseFound, setNoCourseFound] = useState(false);
  const hasFetchedData = useRef(false);

  useEffect(() => {
    if (!hasFetchedData.current) {
      const promises = [];
      const getCourseInfoPromise = new Promise((resolve, reject) => {
        // getCourseInfo(courseID, courseInfoCallback, courseInfoErrorCallback)
        getCourseInfo(
          courseID,
          (data) => resolve(data),
          (error) => reject(error)
        );
      });
      promises.push(getCourseInfoPromise);

      if (!localStorage.getItem('axd_token')) {
        EnrollmentStatusCallback({
          can_enroll: true,
          invitation_only: false,
          is_admin: true,
          is_enrolled: true,
        });
        courseDetailsCallback({});
      } else {
        // getEnrollmentStatus(courseID, EnrollmentStatusCallback);
        const getEnrollmentStatusPromise = new Promise((resolve, reject) => {
          getEnrollmentStatus(
            courseID,
            (data) => resolve(data),
            (error) => reject(error)
          );
        });
        // getCourseDetails(courseID, courseDetailsCallback);
        const getCourseDetailsPromise = new Promise((resolve, reject) => {
          getCourseDetails(
            courseID,
            (data) => resolve(data),
            (error) => reject(error)
          );
        });
        const getStripeProductPromise = new Promise((resolve, reject) => {
          getStripeProduct(courseID, (data) => resolve(data));
        });
        promises.push(
          getEnrollmentStatusPromise,
          getCourseDetailsPromise,
          getStripeProductPromise
        );
      }
      Promise.allSettled(promises).then((results) => {
        if (results.length > 0 && results[0].status === 'fulfilled') {
          courseInfoCallback(results[0].value);
        } else if (results.length > 0 && results[0].status === 'rejected') {
          courseInfoErrorCallback(results[0].reason);
        }
        if (results.length > 1 && results[1].status === 'fulfilled') {
          EnrollmentStatusCallback(results[1].value);
        }
        if (results.length > 2 && results[2].status === 'fulfilled') {
          courseDetailsCallback(results[2].value);
        }
        if (results.length > 3 && results[3].status === 'fulfilled') {
          stripeProductCallback(results[3].value);
        } else if (results.length > 3 && results[3].status === 'rejected') {
          stripeProductErrorCallback(results[3].reason);
        }
      });
      hasFetchedData.current = true;
    }
  }, []);

  const courseDetailsCallback = (data) => {
    setCourseDetails(data);
    setCourseDetailsLoaded(true);
  };
  const courseInfoCallback = (data) => {
    setCourseInfo(data);
    setCourseInfoLoaded(true);
  };

  const courseInfoErrorCallback = (error) => {
    setNoCourseFound(true);
    setCourseInfoLoaded(true);
  };

  const EnrollmentStatusCallback = (data) => {
    setEnrollmentStatus(data);
    setEnrollmentStatusLoaded(true);
  };

  const stripeProductCallback = (data) => {
    setStripeProduct(data);
    setStripeProductLoaded(true);
  };

  const stripeProductErrorCallback = (error) => {
    setStripeProduct(null);
    setStripeProductLoaded(false);
  };

  return [
    courseInfo,
    courseInfoLoaded,
    courseDetails,
    setCourseDetails,
    enrollmentStatus,
    enrollmentStatusLoaded,
    noCourseFound,
    stripeProduct,
    stripeProductLoaded,
  ];
}

export default useCourseDetail;
