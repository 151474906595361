import React, { useContext, useEffect, useRef, useState } from 'react';
import { IframeContext } from '../context/iframe-context';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { FaArrowLeft, FaArrowRight, FaBookReader } from 'react-icons/fa';
import './CourseUnitIframe.css'
import useCourseUnitIframe from '../../../hooks/useCourseUnitIframe';
import { getParameterByName } from '../../../hooks/helpers';
import { getFirstAvailableUnit, getIframeURL } from '../../../hooks/utils/iframe';
import { useParams } from 'react-router-dom';

export const CourseUnitIframe = () => {
  const {
    edxIframe,
    edxIframeLoading,
    isCourseContentAvailable,
    setEdxIframeLoading,
    courseData,
    setEdxIframe,
    setRefresher
  } = useContext(IframeContext);

  const [unitID, setUnitID] = useState("")

  const { courseID } = useParams();

  const { navigator } = useCourseUnitIframe(courseData, getParameterByName('unit_id') || getFirstAvailableUnit(courseData).id)

  const handleIframeUrl = (url) => {
    setEdxIframe(url);
  };

  const courseIframeRef= useRef(null)

  const navigateEdxURL = (unitID) => {
    setRefresher(Date.now())
    const newURL = `/courses/${courseID}/course?unit_id=${unitID}`;
    setEdxIframeLoading(true)
    window.history.pushState(null, 'Course', newURL);
    getIframeURL(courseID, courseData, handleIframeUrl);
  }

  const handlePreviousBtnClick = () => {
    const target = navigator.moveToPrevious()
    if(!target){
      return
    }
    setUnitID(getParameterByName('unit_id') || getFirstAvailableUnit(courseData).id)
    setTimeout(()=>{
      navigateEdxURL(target.id)
    }, 100)
  }

  const handleNextBtnClick = () => {
    const target = navigator.moveToNext()
    if(!target){
      return
    }
    setUnitID(getParameterByName('unit_id') || getFirstAvailableUnit(courseData).id)
    setTimeout(()=>{
      navigateEdxURL(target.id)
    }, 100)
  }
  return (
    <>
      <div className={'course-main-content-header-block'}>
        <div className={'course-title-block'}>
          <FaBookReader size={15} color={"var(--accent)"} /> <span>{navigator.getCurrentBlock().display_name}</span>
        </div>
        <div className={'course-next-previous-btn-container'}
             style={{ justifyContent: navigator.isPreviousHidden() ? 'end' : 'space-between' }}>
          {
            !navigator.isPreviousHidden() && (
              <div title={"Previous"} className={'course-browse-btn-block'} onClick={() => handlePreviousBtnClick()}>
                <div className={'course-browse-icon-container'}>
                  <FaArrowLeft size={15} color={'#FFF'} />
                </div>
                {/*<span>Previous</span>*/}
              </div>
            )
          }
          {
            !navigator.isNextHidden() && (
              <div title={"Next"} className={'course-browse-btn-block'} onClick={() => handleNextBtnClick()}>
                {/*<span>Next</span>*/}
                <div className={'course-browse-icon-container'}>
                  <FaArrowRight size={15} color={'#FFF'} />
                </div>
              </div>
            )
          }
        </div>
      </div>
      <div className="html-embed-19 w-embed w-iframe p-relative">
        {edxIframeLoading && isCourseContentAvailable && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <IBLSpinLoader size={40} color={"var(--accent)"} />
          </div>
        )}

        {isCourseContentAvailable ? (
          <>
            <iframe
              id="edx-iframe"
              title="Course InnerWare"
              sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox allow-downloads"
              frameBorder={0}
              ref={courseIframeRef}
              src={edxIframe}
              style={{
                width: '100%',
                height: 'calc(100vh - 100px - 62px)',
              }}
              allowFullScreen
              onLoad={() => setEdxIframeLoading(false)}
            ></iframe>
          </>
        ) : (
          <>
            <div style={{ textAlign: 'center' }}>There is no content here</div>
          </>
        )}
      </div>
    </>


  );
};
